import React, { useEffect, useState } from "react";
import axios from "axios";
import "./home.css";
import digicoffer from "./digicoffer.png";
import { useNavigate, useLocation } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [authEmail, setAuthEmail] = useState("");
  const [message, setMessage] = useState("");

  const changeEmail = (e) => {
    setAuthEmail(e.target.value);
    if (message != "") {
      setMessage("");
    }
  };

  const isValidEmail = (e) => {
    e.preventDefault();
    if (authEmail !== "") {
      axios({
        method: "GET",
        url: `https://dev2.sso.api.digicoffer.com/getauthtype/isvalid/${authEmail}`, //use ID1000 for SAML 2.0 Authentication and ID1001 for default internal Authentication
        withCredentials: true,
      })
        .then((response) => {
          // console.log(response);
          // if (response.data.authType === "SAML") setisExternal(true);
          // else window.location.href = "https://dev2.lauditor.com/login";
          console.log(response);
          navigate("/login", {
            state: {
              authEmail: authEmail,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          setMessage("Account not Found");
          // setisExternal(false);
        });
    } else {
      console.log("Email is required");
      setMessage("Email is Required");
    }
  };

  return (
    <>
      <div className="login-container">
        <div className="left-side">
          <img className="image-class" src={digicoffer} />
          <h1>Sign in</h1>
          <p>to continue to Digicoffer</p>
        </div>
        <div className="right-side">
          <form className="login-form">
            <div className="form-group">
              <input
                type="email"
                id="authEmail"
                name="authEmail"
                className="form-input"
                placeholder="Email"
                required
                onChange={changeEmail}
              />
            </div>
            <div className="form-group">{message}</div>
            <button onClick={isValidEmail}>Next</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Home;
