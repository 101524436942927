import React, { Profiler, useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import {
  useNavigate,
  useLocation,
  isRouteErrorResponse,
} from "react-router-dom";

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-message">Loading...</div>
    </div>
  );
};

const Login = () => {
  const location = useLocation();
  const { authEmail } = location.state || {
    authEmail: "",
  };
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [isExternal, setisExternal] = useState(false); //Option to identify whether authentication  is DB based or External
  const [provider, setProvider] = useState("");
  //const proId = "ID1000"; //ID1001 for DB Login, ID1000 for Okta login

  const redirectToLogin = () => {
    //redirect indirection from express Server to Saml
    window.location.href = `http://dev2.sso.api.digicoffer.com/login?provider=${provider}`;
  };

  const redirectToDashboard = (data) => {
    //redirect indirection from express Server to Saml
    window.location.href = `https://dev2.lauditor.com/grid?key=${data}`;
  };

  useEffect(() => {
    if (authEmail !== "") {
      axios({
        method: "GET",
        url: `https://dev2.sso.api.digicoffer.com/getauthtype/email/${authEmail}`, //use ID1000 for SAML 2.0 Authentication and ID1001 for default internal Authentication
        withCredentials: true,
      })
        .then((response) => {
          console.log(response.data.authType);
          if (response.data.authType === "SAML") {
            setProvider(response.data.provider);
            setisExternal(true);
          } else window.location.href = "https://dev2.lauditor.com/login";
        })
        .catch((error) => {
          console.log(error);
          setisExternal(false);
          if (error.response) {
            if (error.response.status === 400) {
              window.location.href = "https://dev2.lauditor.com/login";
            } else {
              //need to see when to remove this else part, when account does not exist
              window.location.href = "https://dev2.lauditor.com/login";
            }
          }
        });
    } else {
      setisExternal(true);
    }
  }, [authEmail]);

  useEffect(() => {
    if (isExternal) {
      setisExternal(false);
      console.log("Checking to see if we are authneticated with express..");
      axios({
        method: "GET",
        url: "https://dev2.sso.api.digicoffer.com/whoami",
        withCredentials: true,
      })
        .then((response) => {
          //alert(response);
          console.log(`${response.data.user} Saml`);
          if (response.data.user.nameID) {
            setEmail(response.data.user.nameID);
            console.log(email);
            setLoading(false);
            redirectToDashboard(response.data.data);
          } else {
            //alert("redirect...");
            //redirect to login
            console.log("Redirect to login..");
            redirectToLogin(provider);
          }
        })
        .catch((error) => {
          //alert(provider);
          console.log(error);
          //redirect to login
          redirectToLogin(provider);
        });
    }
  }, [isExternal]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="App">
        <h2>Hello, you are Authenticated and your email is {email}</h2>
        <h2>Redirecting...</h2>
      </div>
    </>
  );
};

export default Login;
